import { RefObject, useEffect } from 'react';

function useOnPressKey<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	handler: (event?: KeyboardEvent) => void,
	key: string,
	isOpen: boolean,
): void {
	useEffect(() => {
		const handleKeydown = (event: KeyboardEvent) => {
			if (!isOpen) {
				return ;
			}

			if (event.key === key) {
				handler(event);

				return;
			}
		};

		document.body.addEventListener('keydown', handleKeydown);

		return () => {
			document.body.removeEventListener('keydown', handleKeydown);
		};
	}, [ref, handler, key, isOpen]);
}

export default useOnPressKey;
