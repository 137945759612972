import { IActionWithPayloadTypeCreator } from '_redux/types/Main';

export interface IEditorReduxState {
	customJSComponentIds: string[];
}

export enum EditorActionTypes {
	ADD_JS_COMPONENT = '@@editor/ADD_JS_COMPONENT',
	REMOVE_JS_COMPONENT = '@@editor/REMOVE_JS_COMPONENT',
	CLEAR_JS_COMPONENT = '@@editor/CLEAR_JS_COMPONENT',
}

export type AddJsComponentAction = IActionWithPayloadTypeCreator<
EditorActionTypes.ADD_JS_COMPONENT,
{
	newId: string
}
>

export type RemoveJsComponentAction = IActionWithPayloadTypeCreator<
EditorActionTypes.REMOVE_JS_COMPONENT,
{
	idToRemove: string
}
>;

export type ClearJsComponentAction = {
	type: EditorActionTypes.CLEAR_JS_COMPONENT;
}

export type EditorActions = AddJsComponentAction | RemoveJsComponentAction | ClearJsComponentAction;
