export enum METHOD {
	GET = 'GET',
	DELETE = 'DELETE',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH'
}

export enum RESPONSE_STATUS {
	UNAUTHORIZED = 401,
	ACCESS_DENIED = 403,
	NOT_FOUND = 404,
	UNPROCESSABLE_ENTITY = 422
}

export enum HEADERS {
	ACCEPT = 'accept',
	AUTHORIZATION = 'App-Authorization',
	CONTENT_TYPE = 'Content-Type',
	DISPOSITION = 'content-disposition',
	TOTAL_ITEMS = 'total-items',
}

export enum CONTENT_TYPE {
	GET_FILE = 'application/json',
	POST = 'application/json',
	PATCH = 'application/merge-patch+json',
}

export enum TOKEN_CHECK {
	ACTIVE = 'ACTIVE',
	DISABLE = 'DISABLE'
}

export const TOKEN_UPDATE_BUFFER = 60;

export enum REQUEST_PARAMS {
	PAGE = 'page',
}

export enum ORDER {
	ASC = 'asc',
	DESC = 'desc'
}

export enum API_PATH {
	GET_TOKEN = '/api/token/get',
	REFRESH_TOKEN = '/api/token/refresh',
	RESET_PASSWORD = '/api/reset_passwords',
	TAGS = '/api/tags',
	USERS = '/api/users',
	WEBSITES = '/api/sites',
	PAGES = '/api/pages',
	TEMPLATES = '/api/page_templates',
}

export enum API_PATH_PART {
	BLOCK = 'block',
	COPY = 'copy',
	EXPORT = 'export',
	PAGES = 'pages',
	PUBLISH = 'publish',
	SITES = 'sites',
	UNBLOCK = 'unblock',
	UPDATE_PASSWORD = 'update_password',
}

export const ITEMS_PER_PAGE = 30;

export const DISPOSITION_START_STRING = 'attachment';
