import axios, { AxiosError } from 'axios';

import { ROUTE_PATH } from 'constants/Main';
import { RESPONSE_STATUS } from 'constants/Request';
import history from 'services/history';
import { FetchResponseParams } from 'utils/appFetch';

type AxiosRequestConfigArgs = {
	interceptorsConfig: FetchResponseParams
}

const axiosHttp = axios.create({
	baseURL: process.env.REACT_APP_API_ENTRYPOINT,
});

axiosHttp.interceptors.response.use(
	(response) => response,
	async (error: AxiosError<unknown, AxiosRequestConfigArgs>) => {
		const isNeedToSkip404 = (error?.response?.config as AxiosRequestConfigArgs).interceptorsConfig.skip404;

		if (!isNeedToSkip404 && error.response?.status === RESPONSE_STATUS.NOT_FOUND) {
			history.push(ROUTE_PATH.NOT_FOUND);

			return;
		}

		return Promise.reject(error);
	},
);

export default axiosHttp;
