import './links.scss';

import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

type RedirectLinkProps = {
	className?: string;
	label?: string;
	children?: ReactNode;
	route: string;
	isNewTab?: boolean;
	isOuterLink?: boolean;
}

const RedirectLink: FC<RedirectLinkProps> = ({
	className,
	label,
	route,
	children,
	isNewTab = false,
	isOuterLink = false,
}) => {
	const additionalProps = isNewTab ? {
		target: '_blank',
		rel: 'noopener noreferrer',
	} : {};

	return (
		<span className={clsx('link-wrapper', className)}>
			{isOuterLink ? (
				<a href={route} {...additionalProps}>{label || children}</a>
			) : (
				<Link to={route} {...additionalProps}>
					{label || children}
				</Link>

			)}
		</span>
	);
};

export default RedirectLink;
