import React, { FC } from 'react';

const Logo: FC = () => {
	return (
		<div className='logo'>
			<svg xmlns="http://www.w3.org/2000/svg" width="200" height="41" viewBox="0 0 200 41" fill="none">
				<path d="M21.463 5.84842L33.3195 12.6938V24.2298L36.7677 26.0147V12.6938C36.7677 11.4618 36.1105 10.3234 35.0436 9.70747L23.1871 2.86213C22.1202 2.24615 20.8057 2.24615 19.7388 2.86213L7.88234 9.70747C6.81544 10.3234 6.1582 11.4618 6.1582 12.6938V25.8764L9.60648 23.028L9.60648 12.6938L21.463 5.84842Z" fill="#333333"/>
				<path d="M16.0084 38.1173C16.1993 38.0572 16.3395 37.9302 16.4607 37.7713C17.8254 35.9689 19.1975 34.1728 20.5707 32.3724C20.6175 32.3121 20.6685 32.2528 20.7333 32.1744C20.801 32.2636 20.8539 32.3401 20.9079 32.4123C21.7128 33.5663 22.5168 34.7247 23.3164 35.8819C23.4624 36.09 23.6382 36.2335 23.9001 36.2498C24.2192 36.2716 24.5027 36.077 24.6008 35.777C24.6242 35.7018 24.6445 35.6212 24.6637 35.5449C26.0326 30.1095 27.4005 24.6782 28.7694 19.2428C28.8014 19.1156 28.843 18.9863 28.839 18.859C28.8229 18.368 28.2593 18.0458 27.821 18.2645C27.7541 18.2973 27.6946 18.3364 27.631 18.3745C22.6577 21.3466 17.6856 24.3146 12.7123 27.2868C12.6115 27.3471 12.5149 27.4084 12.431 27.491C12.0475 27.8678 12.1917 28.4767 12.6972 28.6536C12.764 28.675 12.8361 28.6931 12.9081 28.7113C14.2734 29.0551 15.6386 29.3989 17.0038 29.7428C17.0801 29.762 17.1564 29.7812 17.254 29.8058C17.1912 29.8937 17.1466 29.9636 17.0934 30.0314C15.767 31.7713 14.4406 33.5113 13.1173 35.2566C13.0419 35.3593 12.9632 35.4747 12.9375 35.5945C12.8948 35.8002 13.0058 35.9499 13.1689 36.0721C13.8954 36.6248 14.6219 37.1775 15.3484 37.7302C15.4839 37.8364 15.6195 37.9427 15.7582 38.0543C15.843 38.0756 15.9278 38.097 16.0084 38.1173Z" fill="#333333"/>
				<path d="M58.3783 27.5983C60.7576 27.5983 62.7024 26.6569 64.2128 24.7741L67.3162 27.9707C64.8542 30.7431 61.9473 32.1293 58.5956 32.1293C55.2645 32.1293 52.5128 31.0741 50.3404 28.9638C48.1887 26.8535 47.1128 24.1948 47.1128 20.9879C47.1128 17.7604 48.2093 15.0707 50.4024 12.919C52.6162 10.7672 55.3162 9.69139 58.5024 9.69139C62.0611 9.69139 65.0404 11.0466 67.4404 13.7569L64.43 17.1707C62.899 15.2672 60.9956 14.3155 58.7197 14.3155C56.899 14.3155 55.3369 14.9155 54.0335 16.1155C52.7507 17.2948 52.1093 18.8983 52.1093 20.9259C52.1093 22.9328 52.7197 24.5466 53.9404 25.7672C55.1611 26.9879 56.6404 27.5983 58.3783 27.5983Z" fill="#333333"/>
				<path d="M75.3362 31.881H70.6811V8.85346H75.3362V31.881Z" fill="#333333"/>
				<path d="M84.3981 31.881H79.7429V15.1845H84.3981V31.881ZM80.1153 13.0741C79.5981 12.5362 79.3394 11.8845 79.3394 11.119C79.3394 10.3535 79.5981 9.71208 80.1153 9.19484C80.6532 8.65691 81.305 8.38794 82.0705 8.38794C82.836 8.38794 83.4774 8.65691 83.9946 9.19484C84.5325 9.71208 84.8015 10.3535 84.8015 11.119C84.8015 11.8845 84.5325 12.5362 83.9946 13.0741C83.4774 13.5914 82.836 13.85 82.0705 13.85C81.305 13.85 80.6532 13.5914 80.1153 13.0741Z" fill="#333333"/>
				<path d="M96.8116 28.0017C98.3633 28.0017 99.7599 27.2259 101.001 25.6741L103.763 28.7776C101.612 31.0121 99.284 32.1293 96.7806 32.1293C94.2771 32.1293 92.1564 31.3431 90.4185 29.7707C88.7013 28.1776 87.8426 26.1086 87.8426 23.5638C87.8426 20.9983 88.7116 18.919 90.4495 17.3259C92.2082 15.7121 94.2875 14.9052 96.6875 14.9052C97.8875 14.9052 99.0978 15.1535 100.319 15.65C101.56 16.1466 102.646 16.881 103.577 17.8535L101.156 21.019C100.619 20.3776 99.9564 19.881 99.1702 19.5293C98.4047 19.1776 97.6392 19.0017 96.8737 19.0017C95.653 19.0017 94.5978 19.4052 93.7082 20.2121C92.8392 20.9983 92.4047 22.1052 92.4047 23.5328C92.4047 24.9397 92.8392 26.0362 93.7082 26.8224C94.5978 27.6086 95.6323 28.0017 96.8116 28.0017Z" fill="#333333"/>
				<path d="M111.523 31.881H106.868V8.85346H111.523V20.6776L116.706 15.1535H122.571L116.209 21.919L122.819 31.881H117.295L113.044 25.4879L111.523 27.0707V31.881Z" fill="#333333"/>
				<path d="M130.88 18.5362V26.0155C130.88 26.6569 131.045 27.1638 131.377 27.5362C131.708 27.8879 132.101 28.0638 132.556 28.0638C133.425 28.0638 134.159 27.6707 134.759 26.8845L136.497 30.1431C135.049 31.4672 133.518 32.1293 131.904 32.1293C130.311 32.1293 128.956 31.6017 127.839 30.5466C126.742 29.4914 126.194 28.0535 126.194 26.2328V18.5362H124.239V15.1845H126.194V10.1879H130.88V15.1845H134.914V18.5362H130.88Z" fill="#333333"/>
				<path d="M153.455 29.4604C151.573 31.2397 149.359 32.1293 146.814 32.1293C144.29 32.1293 142.2 31.3535 140.545 29.8017C138.89 28.2293 138.062 26.1293 138.062 23.5017C138.062 20.8741 138.9 18.7845 140.576 17.2328C142.273 15.681 144.269 14.9052 146.566 14.9052C148.862 14.9052 150.807 15.5983 152.4 16.9845C154.014 18.3707 154.821 20.2741 154.821 22.6948V25.1776H142.655C142.8 26.0879 143.276 26.8328 144.083 27.4121C144.89 27.9914 145.8 28.281 146.814 28.281C148.448 28.281 149.793 27.7328 150.848 26.6362L153.455 29.4604ZM150.104 21.8569C150 20.8638 149.628 20.0983 148.986 19.5604C148.345 19.0224 147.559 18.7535 146.628 18.7535C145.717 18.7535 144.879 19.0328 144.114 19.5914C143.348 20.15 142.904 20.9052 142.779 21.8569H150.104Z" fill="#333333"/>
				<path d="M162.833 31.881H158.178V8.85346H162.833V20.6776L168.016 15.1535H173.881L167.519 21.919L174.129 31.881H168.605L164.354 25.4879L162.833 27.0707V31.881Z" fill="#333333"/>
				<path d="M190.393 31.881H186.017V29.8638C184.817 31.3741 183.328 32.1293 181.548 32.1293C179.79 32.1293 178.31 31.6224 177.11 30.6086C175.91 29.5741 175.31 28.2086 175.31 26.5121C175.31 24.7948 175.931 23.5121 177.172 22.6638C178.414 21.8155 180.11 21.3914 182.262 21.3914H185.738V21.2983C185.738 19.519 184.797 18.6293 182.914 18.6293C182.107 18.6293 181.248 18.7948 180.338 19.1259C179.448 19.4362 178.693 19.8293 178.072 20.3052L175.993 17.2948C178.186 15.7017 180.69 14.9052 183.504 14.9052C185.531 14.9052 187.186 15.4121 188.469 16.4259C189.752 17.4397 190.393 19.0431 190.393 21.2362V31.881ZM185.707 25.519V24.7121H182.79C180.928 24.7121 179.997 25.2914 179.997 26.45C179.997 27.05 180.214 27.5155 180.648 27.8466C181.104 28.1569 181.745 28.3121 182.572 28.3121C183.4 28.3121 184.124 28.0638 184.745 27.5673C185.386 27.05 185.707 26.3672 185.707 25.519Z" fill="#333333"/>
			</svg>
		</div>
	);
};

export default Logo;
