import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { useHistory } from 'react-router-dom';

import { clearAccountInfo } from '_redux/actions/Account.actions';
import { RootState } from '_redux/store/Main.store';
import { ROUTE_PATH } from 'constants/Main';
import { IUserResponse } from 'interfaces/User';
import { getHomeRoute } from 'services/Main';

export type SecureProps = {
	route?: RouteConfig & {
		roles?: string[];
	};
}

const Secure: FC<SecureProps> = ({
	route,
}) => {
	const {
		roles, routes,
	} = route as RouteConfig;

	const history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state: RootState) => state.Account) as IUserResponse;

	const {
		token, date,
	} = useSelector((state: RootState) => state.Token);

	const isRoleAvailable = useCallback(() => (
		!roles || roles.includes(user.role)
	), [roles, user.role]);

	useEffect(() => {
		if (!token) {
			history.push(ROUTE_PATH.LOGIN);

			dispatch(clearAccountInfo());

			return;
		}

		if (!isRoleAvailable()) {
			history.push(getHomeRoute(user.role, user.plan));
		}
	}, [token, dispatch, date, isRoleAvailable, history, user]);

	return renderRoutes(routes);
};

export default Secure;
