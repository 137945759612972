import React from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';

type AmbivalentProps = {
	route?: RouteConfig;
}

const Ambivalent: React.FC<AmbivalentProps> = (props) =>
	renderRoutes((props.route as RouteConfig).routes);

export default Ambivalent;
