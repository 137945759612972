import { AnyAction, Reducer } from 'redux';

import { AccountActionTypes, IAccountState } from '_redux/types/Account.types';
import { IUserResponse, USER_PLAN } from 'interfaces/User';

const initialState: IAccountState = {
	id: null,
	email: null,
	role: null,
	firstname: null,
	lastname: null,
	phone: null,
	skype: null,
	telegram: null,
	isBlocked: null,
	plan: USER_PLAN.TRIAL,
	cardNumber: null,
	cardMonth: null,
	cardYear: null,
	cardUid: null,
	subscriptionEnd: null,
	subscriptionUid: null,
	subscriptionPrice: null,
	chargeError: null,
};

const AccountReducer: Reducer<IAccountState, AnyAction> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case AccountActionTypes.SAVE_ACCOUNT_INFORMATION:
			return {
				...state,
				...(action.payload as IUserResponse),
			};

		case AccountActionTypes.LOGOUT:
			return initialState;

		default:
			return state;
	}
};

export default AccountReducer;
