import { RefObject, useEffect } from 'react';

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	handler: (event?: MouseEvent) => void,
): void {
	useEffect(() => {
		const handleMouseDown = (event: MouseEvent) => {
			const element = ref.current;

			if (element && !element.contains(event.target as Node)) {
				handler(event);
			}
		};

		document.body.addEventListener('mousedown', handleMouseDown);

		return () => {
			document.body.removeEventListener('mousedown', handleMouseDown);
		};
	}, [ref, handler]);
}

export default useOnClickOutside;
