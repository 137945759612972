import { AxiosError, AxiosPromise, AxiosRequestHeaders } from 'axios';
import _ from 'lodash';

import { showGlobalError } from '_redux/actions/GlobalError.actions';
import { store } from '_redux/store/Main.store';
import { API_PATH,
	CONTENT_TYPE,
	HEADERS,
	METHOD,
	RESPONSE_STATUS,
	TOKEN_CHECK } from 'constants/Request';
import { ErrorUnion, VALIDATION_ERROR_CODE } from 'interfaces/Error';
import axiosHttp from 'utils/appFetch/axios';
import { tokenUtils } from 'utils/appFetch/tokenUtils';

export type FetchResponseParams = {
	tokenCheck?: TOKEN_CHECK;
	isSecondAttempt?: boolean;
	skip404?: boolean;
};

const DEFAULT_FETCH_CONFIG: FetchResponseParams = {
	tokenCheck: TOKEN_CHECK.ACTIVE,
	isSecondAttempt: false,
	skip404: false,
};

const fetchResponse = async <TResponse>(
	url: string,
	method: METHOD,
	body?: object | FormData,
	config?: FetchResponseParams,
): Promise<AxiosPromise<TResponse>> => {
	const fetchConfigs = {
		...DEFAULT_FETCH_CONFIG,
		...config,
	};
	const {
		isSecondAttempt, skip404, tokenCheck,
	} = fetchConfigs;

	await tokenUtils.checkToken(url);

	const {
		token,
	} = store.getState().Token;
	const data = body instanceof FormData ? body : JSON.stringify(body);

	const headers: AxiosRequestHeaders = {
		[HEADERS.AUTHORIZATION]: '',
		[HEADERS.CONTENT_TYPE]: method === METHOD.PATCH ? CONTENT_TYPE.PATCH : CONTENT_TYPE.POST,
	};

	if (token !== null && tokenCheck === TOKEN_CHECK.ACTIVE) {
		headers[HEADERS.AUTHORIZATION] = `Bearer ${token}`;
	}

	const clearedHeaders = _.omitBy(headers, _.isNil);

	const axiosArgs = {
		url,
		method,
		headers: clearedHeaders,
		data,
		interceptorsConfig: {
			skip404,
		},
	};

	try {
		return await axiosHttp(axiosArgs);
	} catch (error) {
		const e: AxiosError<ErrorUnion> = error as AxiosError<ErrorUnion>;

		const isFirstTokenFetch = url === API_PATH.GET_TOKEN;

		const is401 = e.response?.status === RESPONSE_STATUS.UNAUTHORIZED;
		const isStatusError = e.response?.data?.violations?.length
			&& e.response?.data.violations[0].code === VALIDATION_ERROR_CODE.ACCOUNT_STATUS_ERROR_CODE;
		const iDeletedUserError = e.response?.data?.violations?.length
			&& e.response?.data.violations[0].code === VALIDATION_ERROR_CODE.ERROR_CODE;

		if (is401 && (isStatusError || iDeletedUserError)) {
			store.dispatch(showGlobalError());
		}

		if (is401 && !isSecondAttempt && !isFirstTokenFetch) {
			return fetchResponse<TResponse>(url, method, body, {
				tokenCheck,
				isSecondAttempt: true,
			});
		}

		return Promise.reject(error);
	}
};

export default fetchResponse;
