import { AnyAction } from 'redux';

import { AccountActionTypes } from '_redux/types/Account.types';
import { IUserResponse } from 'interfaces/User';

export const saveAccount = (
	response: IUserResponse,
): AnyAction => ({
	type: AccountActionTypes.SAVE_ACCOUNT_INFORMATION,
	payload: response,
});

export const clearAccountInfo = (): AnyAction => ({
	type: AccountActionTypes.LOGOUT,
});
