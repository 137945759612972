export const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const DOMAIN_ALLOWED_CHARACTERS = /^[A-Za-z0-9-]+$/;

export const SLUG_ALLOWED_CHARACTERS = /^[A-Za-z0-9-]+$/;

export const PHONE_NUMBER_REGEXP = /^\+?\d[\d\(\)\ -]{4,18}\d$/;

export const CONTENT_DISPOSITION_FILENAME = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
export const QUOTES_REGEX = /['"]/g;
