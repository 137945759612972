import './Navigation.scss';

import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTE_PATH, USER_NAVIGATION } from 'constants/Main';
import { USER_PLAN, USER_ROLE } from 'interfaces/User';
import { getHomeRoute } from 'services/Main';

import RedirectLink from 'components/atoms/links/RedirectLink';

type NavigationProps = {
	role: USER_ROLE;
	plan: USER_PLAN;
};

const Navigation: FC<NavigationProps> = ({
	role,
	plan,
}) => {
	const location = useLocation();

	const [activeLink, setActiveLink] = useState(getHomeRoute(role, plan));

	useEffect(() => {
		setActiveLink(location.pathname as ROUTE_PATH);
	}, [location]);

	const navigationItems = USER_NAVIGATION.filter((item) => (
		item.roles.includes(role) && (!item.plans.length || item.plans.includes(plan))
	));

	return (
		<ul className='navigation-menu'>
			{navigationItems.map((item) => (
				<li
					key={item.label} className={clsx('menu-item', {
						'active-link': item.route === activeLink,
					})}
				>
					<RedirectLink
						className='menu-link'
						label={item.label}
						route={item.route}
						isOuterLink={item.isOuterLink}
						isNewTab={item.isNewTab}
					/>
				</li>
			))}
		</ul>
	);
};

export default Navigation;
