import { applyMiddleware,
	combineReducers,
	createStore,
	Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import AccountReducer from '_redux/reducers/Account.reducer';
import EditorReducer from '_redux/reducers/Editor.reducer';
import GlobalErrorReducer from '_redux/reducers/GlobalError.reducer';
import TokenReducer from '_redux/reducers/Token.reducer';
import { persistConfigKey } from 'constants/Main';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/es/storage';

export const rootReducer = combineReducers({
	Account: AccountReducer,
	Token: TokenReducer,
	GlobalError: GlobalErrorReducer,
	Editor: EditorReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const persistConfig = {
	key: persistConfigKey,
	storage,
	blacklist: ['GlobalError', 'Editor'],
};

const configureStore = (): Store<RootState> => {
	const middlewares = [thunk];
	const middleWareEnhancer = applyMiddleware(...middlewares);
	const persistedReducer = persistReducer(persistConfig, rootReducer);

	const composeEnhancers = composeWithDevTools({
		maxAge: 30,
	});

	return createStore(persistedReducer, composeEnhancers(middleWareEnhancer));
};

export const store = configureStore();
export const persistor = persistStore(store);
