import { AxiosResponseHeaders } from 'axios';
import { formatInTimeZone } from 'date-fns-tz';

import { ROUTE_PATH, TIMEZONE } from 'constants/Main';
import { CONTENT_DISPOSITION_FILENAME, QUOTES_REGEX } from 'constants/RegExp';
import { DISPOSITION_START_STRING, HEADERS } from 'constants/Request';
import { USER_ROLE, USER_PLAN } from 'interfaces/User';

export const getDomain = (): string => {
	const protocolPostfix = '//';
	const entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

	if (entryPoint?.includes(protocolPostfix)) {
		return entryPoint.split(protocolPostfix)[1];
	}

	return (
		process.env.REACT_APP_API_ENTRYPOINT as string
	);
};

export const getHomeRoute = (role: USER_ROLE, plan: USER_PLAN): ROUTE_PATH => {
	return !role || plan === USER_PLAN.UNPAID
		? ROUTE_PATH.PROFILE
		: (role === USER_ROLE.ROLE_USER ? ROUTE_PATH.DASHBOARD : ROUTE_PATH.USERS);
};

export const formatDisplayDate = (
	date: Date | string | null | number,
	pattern: string,
): string => (
	date && pattern && formatInTimeZone(new Date(date), TIMEZONE, pattern) || ''
);

export const getFileNameFromHeader = (headers: AxiosResponseHeaders, defaultFilename = '') => {
	let filename = defaultFilename;
	const disposition = headers[HEADERS.DISPOSITION];

	if (disposition && disposition.indexOf(DISPOSITION_START_STRING) !== -1) {
		const matches = CONTENT_DISPOSITION_FILENAME.exec(disposition);

		if (matches !== null && matches[1]) {
			filename = matches[1].replace(QUOTES_REGEX, '');
		}
	}

	return filename;
};

export const isHyphenPositionValidForSlugAndDomain = (value: string): boolean => (
	!value.startsWith('-') && !value.endsWith('-')
);
