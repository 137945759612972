import { USER_PLAN, USER_ROLE } from 'interfaces/User';

export interface IAccountState {
	readonly id: number | null;
	readonly email: string | null;
	readonly role: USER_ROLE | null;
	readonly firstname: string | null;
	readonly lastname: string | null;
	readonly phone: string | null;
	readonly skype: string | null;
	readonly telegram: string | null;
	readonly isBlocked: boolean | null;
	readonly plan: USER_PLAN | USER_PLAN.TRIAL;
	readonly cardNumber: string | null;
	readonly cardMonth: string | null;
	readonly cardYear: string | null;
	readonly cardUid: string | null;
	readonly subscriptionEnd: string | null;
	readonly subscriptionUid: string | null;
	readonly subscriptionPrice: string | null;
	readonly chargeError: string | null;
}

export enum AccountActionTypes {
	LOGOUT = '@@account/LOGOUT',
	SAVE_ACCOUNT_INFORMATION = '@@account/SAVE_ACCOUNT_INFORMATION',
}
