import './ProfileDropdown.scss';

import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearAccountInfo } from '_redux/actions/Account.actions';
import { clearTokenInfo } from '_redux/actions/Token.actions';
import { ROUTE_PATH } from 'constants/Main';
import { DropDownOption } from 'interfaces/Main';
import { IUserResponse, USER_ROLE } from 'interfaces/User';

import DropDown from 'components/atoms/dropdown/Dropdown';
import ArrowTopIcon from 'components/atoms/svg/ArrowTopIcon';

type ProfileDropdownProps = {
	user: IUserResponse;
};

const ProfileDropdown: FC<ProfileDropdownProps> = ({
	user,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleProfile = useCallback(() => {
		history.push(ROUTE_PATH.PROFILE);
	}, [history]);

	const handleLogout = useCallback(() => {
		const changesCount = localStorage.getItem('changesCount');
		const MINIMUM_EDITOR_CHANGES = 1;

		if (Number(changesCount) < MINIMUM_EDITOR_CHANGES) {
			dispatch(clearAccountInfo());
			dispatch(clearTokenInfo());

			return;
		}

		const isConfirmed = window.confirm('Leave Site? Changes you made may not be saved.');

		if (!isConfirmed) {
			return;
		}

		dispatch(clearAccountInfo());
		dispatch(clearTokenInfo());

		history.push(ROUTE_PATH.LOGIN);
	}, [dispatch, history]);

	const profileDropdownOptions: DropDownOption[] = [{
		label: 'My profile',
		roles: [USER_ROLE.ROLE_USER],
		onClick: handleProfile,
	}, {
		label: 'Log Out',
		onClick: handleLogout,
	}];

	return (
		<div className='profile-dropdown-wrapper'>
			<DropDown options={profileDropdownOptions}>
				<span>{user.firstname || user.email}</span>
				<ArrowTopIcon className='profile-dropdown-arrow' />
			</DropDown>
		</div>
	);
};

export default ProfileDropdown;
