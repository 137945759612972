import { AnyAction } from 'redux';

import { ITokenState, TokenActionTypes } from '_redux/types/Token.types';
import { ITokenResponse } from 'interfaces/User';

export const saveToken = (
	response: ITokenResponse,
): AnyAction => ({
	type: TokenActionTypes.SAVE_TOKEN,
	payload: response,
});

export const clearTokenInfo = (): AnyAction => ({
	type: TokenActionTypes.LOGOUT,
});

export const setTokenFromStorage = (account: ITokenState): AnyAction => ({
	type: TokenActionTypes.SET_FROM_STORAGE,
	payload: account,
});
