import { AnyAction, Reducer } from 'redux';

import { ITokenState, TokenActionTypes } from '_redux/types/Token.types';
import { ITokenResponse } from 'interfaces/User';

const initialState: ITokenState = {
	date: null,
	expiresIn: null,
	refreshToken: null,
	token: null,
	userId: null,
};

const TokenReducer: Reducer<ITokenState, AnyAction> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case TokenActionTypes.SAVE_TOKEN:
			return {
				...state,
				...(action.payload as ITokenResponse),
				date: new Date().toISOString(),
			};

		case TokenActionTypes.LOGOUT:
			return initialState;

		case TokenActionTypes.SET_FROM_STORAGE:
			return {
				...state,
				...(action.payload as ITokenState),
			};

		default:
			return state;
	}
};

export default TokenReducer;
