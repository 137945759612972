import './buttons.scss';

import clsx from 'clsx';
import { FC, ReactElement } from 'react';

export type ButtonProps = {
	className?: string;
	disabled?: boolean;
	label: string;
	icon?: ReactElement;
	isSecondary?: boolean;
	isGradient?: boolean;
	onClick: () => void;
}

const Button: FC<ButtonProps> = ({
	className,
	disabled = false,
	label,
	icon,
	isSecondary = false,
	isGradient = false,
	onClick,
}) => (
	<button
		className={clsx('main-button', className, {
			'with-icon': Boolean(icon),
			'secondary-button': isSecondary,
			'gradient-button': isGradient,
			'disabled-button': disabled,
		})}
		disabled={disabled}
		type='button'
		onClick={onClick}
	>
		{icon || null}
		{label}
	</button>
);

export default Button;
