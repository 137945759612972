import { FC } from 'react';

type ClearIconProps = {
	className?: string;
}

const ClearIcon: FC<ClearIconProps> = ({
	className,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path d="M19.0005 6.41L17.5905 5L12.0005 10.59L6.41049 5L5.00049 6.41L10.5905 12L5.00049 17.59L6.41049 19L12.0005 13.41L17.5905 19L19.0005 17.59L13.4105 12L19.0005 6.41Z" fill="#A0A0A0"/>
	</svg>
);

export default ClearIcon;
