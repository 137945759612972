import { useEffect } from 'react';

import { LIVE_AGENT_BUTTON_ID, LIVE_AGENT_SCRIPT_ID, LIVE_AGENT_URL } from 'constants/LiveAgent';

const LiveAgent = () => {
	useEffect(() => {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.id = 'live-agent';
		script.innerHTML = `
			(function(d, src, c) {
				var t=d.scripts[d.scripts.length-1],
				s=d.createElement('script');
				s.id='${LIVE_AGENT_SCRIPT_ID}';
				s.async=true;
				s.src=src;
				s.onload=s.onreadystatechange=function(){
					var rs=this.readyState;
					if(rs&&(rs!='complete')&&(rs != 'loaded')){
						return;
					}
					c(this);
				}
				t.parentElement.insertBefore(s,t.nextSibling);
			})(document, '${LIVE_AGENT_URL}',function(e){ LiveAgent.createButton('${LIVE_AGENT_BUTTON_ID}',e)})
		`;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return null;
};

export default LiveAgent;
