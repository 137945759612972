import { FC } from 'react';

import { DropDownOption } from 'interfaces/Main';

export type DropDownItemProps = {
	item: DropDownOption;
};

const DropDownItem: FC<DropDownItemProps> = ({
	item: {
		label,
		onClick,
	},
}) => {
	return (
		<li className='dropdown-item'>
			<button
				type='button'
				onClick={onClick}
			>
				{label}
			</button>
		</li>
	);
};

export default DropDownItem;
