import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes, RouteConfig } from 'react-router-config';

import { RootState } from '_redux/store/Main.store';
import { API_PATH, METHOD } from 'constants/Request';
import { IUserResponse } from 'interfaces/User';
import { getHomeRoute } from 'services/Main';
import fetchResponse from 'utils/appFetch';
import { tokenUtils } from 'utils/appFetch/tokenUtils';

type UnsecureProps = {
	route?: RouteConfig;
}

const Unsecure: React.FC<UnsecureProps> = (props) => {
	const {
		route: {
			routes,
		},
	} = props as RouteConfig;

	const {
		push,
	} = useHistory();

	const {
		Account, Token,
	} = useSelector((state: RootState) => state);

	const handleToken = useCallback(async (): Promise<void> => {
		try {
			await tokenUtils.updateTokenIfNeeded();

			if (Account.role) {
				push(getHomeRoute(Account.role, Account.plan));
			} else {
				const userResponse = await fetchResponse<IUserResponse>(
					`${API_PATH.USERS}/${Token.userId}`,
					METHOD.GET,
				);

				push(getHomeRoute(userResponse.data.role, userResponse.data.plan));
			}
		} catch (e) {
			throw new Error(e as string | undefined);
		}
	}, [Account.role, Account.plan, Token.userId, push]);

	useEffect(() => {
		const updateUserInfo = async () => {
			await handleToken();
		};

		if (Token.token) {
			updateUserInfo();
		}
	}, [Token.token, handleToken]);

	return renderRoutes(routes);
};

export default Unsecure;
