import { AnyAction } from 'redux';

import { GlobalErrorActionTypes } from '_redux/types/GlobalError.types';

export const showGlobalError = (): AnyAction => ({
	type: GlobalErrorActionTypes.SET_ERROR,
});

export const clearErrorInfo = (): AnyAction => ({
	type: GlobalErrorActionTypes.CLEAR_ERROR,
});
