import { AUTO_LOGOUT_KEY, AUTO_LOGOUT_SEC } from 'constants/Main';

const STORED_EXPIRED_TIME_RADIX = 10;
const MS_IN_ONE_SEC = 1000;
const UPDATE_STORAGE_TIMEOUT_MS = 900;
const CHECK_LOGOUT_TIME_INTERVAL_MS = 1000;

class AutoLogoutTimer {
	private logoutTimeoutSec: number = AUTO_LOGOUT_SEC;

	private checkIsLogoutNeeded: NodeJS.Timer | undefined;
	private updateTimeout: NodeJS.Timeout | undefined;

	private listener = this.updateLogoutTime.bind(this);

	constructor({
		onExpired,
	}) {
		const expiredTime = this.getExpiredTime();

		if (expiredTime && expiredTime > 0 && expiredTime < Date.now()) {
			// expired without active tab
			onExpired();
			this.cleanUp();

			return;
		}

		this.runListeners();
		this.startInterval();
	}

	private getExpiredTime = (): number => {
		return parseInt(localStorage.getItem(AUTO_LOGOUT_KEY) as string, STORED_EXPIRED_TIME_RADIX);
	};

	private startInterval(): void {
		this.updateLogoutTime();

		this.checkIsLogoutNeeded = setInterval(() => {
			const expiredTime = this.getExpiredTime();

			if (expiredTime < Date.now()) {
				// expired with active tab
				this.updateLogoutTime();
			}
		}, CHECK_LOGOUT_TIME_INTERVAL_MS);
	}

	private updateLogoutTime(): void {
		if (this.updateTimeout) {
			clearTimeout(this.updateTimeout);
		}

		this.updateTimeout = setTimeout(() => {
			const newTime = Date.now() + this.logoutTimeoutSec * MS_IN_ONE_SEC;
			localStorage.setItem(AUTO_LOGOUT_KEY, newTime.toString());
		}, UPDATE_STORAGE_TIMEOUT_MS);
	}

	private runListeners(): void {
		window.addEventListener('mousemove', this.listener);
		window.addEventListener('scroll', this.listener);
		window.addEventListener('keydown', this.listener);
		document.addEventListener('visibilitychange', this.listener);
	}

	public cleanUp(): void {
		localStorage.removeItem(AUTO_LOGOUT_KEY);

		clearInterval(this.checkIsLogoutNeeded as NodeJS.Timer);

		window.removeEventListener('mousemove', this.listener);
		window.removeEventListener('scroll', this.listener);
		window.removeEventListener('keydown', this.listener);
		document.removeEventListener('visibilitychange', this.listener);
	}
}

export default AutoLogoutTimer;
