import { lazy } from 'react';
import { RouteConfig } from 'react-router-config';

import { USER_ROLE } from 'interfaces/User';

import Ambivalent from 'components/secureRouteManagement/Ambivalent';
import Secure from 'components/secureRouteManagement/Secure';
import Unsecure from 'components/secureRouteManagement/Unsecure';

import { ROUTE_PATH, ROUTE_EDITOR_ACTIONS, ROUTE_EDITOR_PATH } from './Main';

type RouteExtendedConfig = RouteConfig & {
	roles?: USER_ROLE[]
}

export const routes: RouteExtendedConfig[] = [
	{
		path: [
			`${ROUTE_EDITOR_PATH.TEMPLATE}/:templateId`,
			`${ROUTE_EDITOR_PATH.TEMPLATE}/:templateId${ROUTE_EDITOR_ACTIONS.PREVIEW}`,
			`${ROUTE_EDITOR_PATH.PAGE}/:pageId${ROUTE_EDITOR_ACTIONS.PREVIEW}`,
		],
		exact: true,
		component: Secure,
		routes: [
			{
				component: lazy(() => import('pages/Editor/EditorPage')),
			},
		],
	},
	{
		path: [
			ROUTE_PATH.TEMPLATES,
		],
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_ADMIN, USER_ROLE.ROLE_SUPER_ADMIN],
		routes: [
			{
				component: lazy(() => import('pages/Templates/Templates')),
			},
		],
	},
	{
		path: [
			`${ROUTE_EDITOR_PATH.TEMPLATE}/:templateId${ROUTE_EDITOR_ACTIONS.EDIT}`,
		],
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_ADMIN, USER_ROLE.ROLE_SUPER_ADMIN],
		routes: [
			{
				component: lazy(() => import('pages/Editor/EditorPage')),
			},
		],
	},
	{
		path: [
			`${ROUTE_PATH.WEBSITE}/:websiteId${ROUTE_EDITOR_PATH.PAGE}/:pageId${ROUTE_EDITOR_ACTIONS.EDIT}`,
		],
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_USER],
		routes: [
			{
				component: lazy(() => import('pages/Editor/EditorPage')),
			},
		],
	},
	{
		path: ROUTE_PATH.LOGIN,
		exact: true,
		component: Unsecure,
		routes: [
			{
				component: lazy(() => import('pages/Auth/Login')),
			},
		],
	},
	{
		path: ROUTE_PATH.SIGN_UP,
		exact: true,
		component: Unsecure,
		routes: [
			{
				component: lazy(() => import('pages/Auth/SignUp')),
			},
		],
	},
	{
		path: ROUTE_PATH.RESET_PASSWORD,
		exact: true,
		component: Unsecure,
		routes: [
			{
				component: lazy(() => import('pages/Auth/ResetPassword')),
			},
		],
	},
	{
		path: `${ROUTE_PATH.RESET_PASSWORD_CONFIRM}/:hash`,
		exact: true,
		component: Unsecure,
		routes: [
			{
				component: lazy(() => import('pages/Auth/ResetPasswordConfirm')),
			},
		],
	},
	{
		path: ROUTE_PATH.TERMS_AND_CONDITIONS,
		exact: true,
		component: Ambivalent,
		routes: [
			{
				component: lazy(() => import('pages/TermsAndConditions/TermsAndConditions')),
			},
		],
	},
	{
		path: ROUTE_PATH.DASHBOARD,
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_USER],
		routes: [
			{
				component: lazy(() => import('pages/Dashboard/Dashboard')),
			},
		],
	},
	{
		path: `${ROUTE_PATH.USERS}/:userId${ROUTE_PATH.WEBSITES}`,
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_ADMIN, USER_ROLE.ROLE_SUPER_ADMIN],
		routes: [
			{
				component: lazy(() => import('pages/Admin/Website/WebsiteList')),
			},
		],
	},
	{
		path: `${ROUTE_PATH.USERS}/:userId${ROUTE_PATH.WEBSITES}/:websiteId`,
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_ADMIN, USER_ROLE.ROLE_SUPER_ADMIN],
		routes: [
			{
				component: lazy(() => import('pages/Admin/Page/PageList')),
			},
		],
	},
	{
		path: `${ROUTE_PATH.WEBSITE}/:id`,
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_USER],
		routes: [
			{
				component: lazy(() => import('pages/Website/Website')),
			},
		],
	},
	{
		path: `${ROUTE_PATH.PAGE_WAY}/:websiteId/:pageId`,
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_USER],
		routes: [
			{
				component: lazy(() => import('pages/Page/CreatePageChooseWay')),
			},
		],
	},
	{
		path: ROUTE_PATH.PROFILE,
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_USER],
		routes: [
			{
				component: lazy(() => import('pages/Profile/Profile')),
			},
		],
	},
	{
		path: ROUTE_PATH.USERS,
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_ADMIN, USER_ROLE.ROLE_SUPER_ADMIN],
		routes: [
			{
				component: lazy(() => import('pages/Admin/Users/Users')),
			},
		],
	},
	{
		path: `${ROUTE_PATH.USERS}/:userId${ROUTE_PATH.PROFILE}`,
		exact: true,
		component: Secure,
		roles: [USER_ROLE.ROLE_ADMIN, USER_ROLE.ROLE_SUPER_ADMIN],
		routes: [
			{
				component: lazy(() => import('pages/Admin/Users/ProfileForAdmin')),
			},
		],
	},
	{
		path: ROUTE_PATH.LANDING_PAGE,
		exact: true,
		component: Ambivalent,
		routes: [
			{
				component: lazy(() => import('pages/LandingPage/LandingPage')),
			},
		],
	},
	{
		path: ROUTE_PATH.PRIVACY_POLICY,
		exact: true,
		component: Ambivalent,
		routes: [
			{
				component: lazy(() => import('pages/PrivacyPolicy/PrivacyPolicy')),
			},
		],
	},
	{
		path: [ROUTE_PATH.NOT_FOUND, '*'],
		exact: true,
		component: Secure,
		routes: [
			{
				component: lazy(() => import('pages/NotFound/NotFound')),
			},
		],
	},
];
