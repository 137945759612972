import './Footer.scss';

import { FC } from 'react';

type FooterProps = {};

const Footer: FC<FooterProps> = () => {
	return (
		<footer className='app-footer'>
			<span>© 2021-2022 Clickteka. All rights reserved.</span>
		</footer>
	);
};

export default Footer;
