import './App.scss';

import { Suspense, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { clearAccountInfo } from '_redux/actions/Account.actions';
import { clearTokenInfo } from '_redux/actions/Token.actions';
import { persistConfig, RootState, store } from '_redux/store/Main.store';
import { HIDE_TOAST_MS, ROUTE_PATH, ROUTE_EDITOR_ACTIONS, ROUTE_EDITOR_PATH } from 'constants/Main';
import { routes } from 'constants/Routes';
import AutoLogoutTimer from 'utils/AutoLogoutTimer';
import { crossTabListener } from 'utils/crossTabUtils';

import AccountAccessHandler from 'components/atoms/modals/AccountAccessHandler';
import LiveAgent from 'components/LiveAgent/LiveAgent';
import Footer from 'components/molecules/Footer/Footer';
import Header from 'components/molecules/Header/Header';

function App() {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const {
		token,
	} = useSelector((state: RootState) => state.Token);

	const isHeaderRequired = () => {
		return token &&
            location.pathname !== ROUTE_PATH.LANDING_PAGE &&
            !location.pathname.includes(ROUTE_EDITOR_ACTIONS.PREVIEW) &&
			location.pathname !== ROUTE_PATH.PRIVACY_POLICY &&
			location.pathname !== ROUTE_PATH.TERMS_AND_CONDITIONS;
	};

	const isFooterRequired = () => {
		return token &&
			!location.pathname.includes(ROUTE_EDITOR_ACTIONS.EDIT) &&
			!location.pathname.includes(ROUTE_EDITOR_ACTIONS.PREVIEW) &&
			!location.pathname.includes(ROUTE_PATH.TEMPLATES) &&
			location.pathname !== ROUTE_PATH.LANDING_PAGE &&
			location.pathname !== ROUTE_PATH.DASHBOARD &&
			location.pathname !== ROUTE_PATH.PRIVACY_POLICY &&
			location.pathname !== ROUTE_PATH.TERMS_AND_CONDITIONS;
	};

	useEffect(() => {
		const isEditorRoute = () => {
			if (
				!location.pathname.includes(ROUTE_EDITOR_ACTIONS.EDIT) &&
				!location.pathname.includes(ROUTE_EDITOR_ACTIONS.PREVIEW) &&
				!location.pathname.includes(ROUTE_EDITOR_PATH.TEMPLATE) &&
				!location.pathname.includes(ROUTE_EDITOR_PATH.PAGE)
			) {
				localStorage.setItem('changesCount', '0');
			}
		};
		isEditorRoute();
		window.addEventListener('storage', crossTabListener(store, persistConfig));
	}, [location]);

	const logout = useCallback(() => {
		if (!token) {
			return;
		}

		dispatch(clearTokenInfo());
		dispatch(clearAccountInfo());
		history.push(ROUTE_PATH.LOGIN);
	}, [dispatch, history, token]);

	useEffect(() => {
		const timer = new AutoLogoutTimer({
			onExpired: logout,
		});

		return () => {
			timer.cleanUp();
		};
	}, [logout]);

	return (
		<Suspense fallback={<div />}>
			<ToastContainer
				autoClose={HIDE_TOAST_MS}
				newestOnTop
				position='top-right'
				className='app-toast-container'
			/>

			<AccountAccessHandler />

			<LiveAgent />

			<div className="App">
				{ isHeaderRequired() && <Header />}
				{renderRoutes(routes)}
				{ isFooterRequired() && <Footer/> }
			</div>
		</Suspense>
	);
}

export default withRouter(App);
