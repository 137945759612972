import { Store } from 'redux';
import { PersistConfig } from 'redux-persist';

import { RootState } from '_redux/store/Main.store';
import { REHYDRATE } from 'redux-persist/es/constants';
import getStoredState from 'redux-persist/es/getStoredState';

export const crossTabListener = (
	store: Store<RootState>,
	persistConfig: PersistConfig<RootState>,
): (() => Promise<void>) => {
	const doRehydrateWithNewState = async (): Promise<void> => {
		const state = await getStoredState(persistConfig);

		const {
			token,
		} = store.getState().Token;

		if (token) {
			store.dispatch({
				type: REHYDRATE,
				key: persistConfig.key,
				payload: state,
			});
		}
	};

	return doRehydrateWithNewState;
};
