import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '_redux/store/Main.store';
import { ROUTE_PATH } from 'constants/Main';
import { IUserResponse } from 'interfaces/User';
import { getHomeRoute } from 'services/Main';

import RedirectLink from 'components/atoms/links/RedirectLink';
import Logo from 'components/atoms/svg/Logo';

type ClickableLogoProps = {};

const ClickableLogo: FC<ClickableLogoProps> = () => {
	const {
		token,
	} = useSelector((state: RootState) => state.Token);
	const location = useLocation();
	const user = useSelector((state: RootState) => state.Account) as IUserResponse;

	const [targetRoute, setTargetRoute] = useState(ROUTE_PATH.LANDING_PAGE);

	useEffect(() => {
		if (location.pathname === ROUTE_PATH.LANDING_PAGE || !token) {
			setTargetRoute(ROUTE_PATH.LANDING_PAGE);

			return;
		}

		setTargetRoute(getHomeRoute(user.role, user.plan));
	}, [location.pathname, token, user.role, user.plan]);

	return (
		<RedirectLink className='logo-link' route={targetRoute}>
			<Logo />
		</RedirectLink>
	);
};

export default ClickableLogo;
