import { IUserNavigation } from 'interfaces/Main';
import { USER_PLAN, USER_ROLE } from 'interfaces/User';

export enum ROUTE_PATH {
	// auth activity
	LOGIN = '/login',
	SIGN_UP = '/sign-up',
	RESET_PASSWORD = '/reset-password',
	RESET_PASSWORD_CONFIRM = '/reset_passwords',
	TERMS_AND_CONDITIONS = '/terms-and-conditions',
	PRIVACY_POLICY = '/privacy-policy',

	// app pages
	LANDING_PAGE = '/',
	DASHBOARD = '/dashboard',
	WEBSITE = '/website',
	PAGE_WAY = '/page-way',
	PROFILE = '/profile',
	NOT_FOUND = '/404',

	// admin area
	USERS = '/users',
	TEMPLATES = '/templates',
	WEBSITES = '/websites',
}

export enum OUTER_LINKS {
	SUPPORT = 'https://support.clickteka.com'
}

export enum ROUTE_EDITOR_PATH {
	TEMPLATE = '/template',
	PAGE = '/page',
}

export enum ROUTE_EDITOR_ACTIONS {
	EDIT = '/edit',
	PREVIEW = '/preview',
}

export enum ANCHOR_ID {
	FEATURES= 'features',
	TEMPLATES = 'templates',
	SUBSCRIPTION_TYPE = 'subscription-type',
	FAQ = 'faq',
}

export const persistConfigKey = 'root';

export const AUTO_LOGOUT_MINUTES = 30;

export const HIDE_TOAST_MS = 5000;

export const EMAIL_MAX_LENGTH = 255;

export const INDEX_PAGE_SLUG = 'index';

export const TOOLTIP_ROOT_KEY = 'root';

export const USER_NAVIGATION: IUserNavigation[] = [
	{
		label: 'Dashboard',
		route: ROUTE_PATH.DASHBOARD,
		roles: [USER_ROLE.ROLE_USER],
		plans: [USER_PLAN.FREE, USER_PLAN.TRIAL, USER_PLAN.PREMIUM],
	},
	{
		label: 'Support',
		route: OUTER_LINKS.SUPPORT,
		roles: [USER_ROLE.ROLE_USER],
		plans: [], // all plans
		isOuterLink: true,
		isNewTab: true,
	},
	{
		label: 'Users',
		route: ROUTE_PATH.USERS,
		roles: [USER_ROLE.ROLE_ADMIN, USER_ROLE.ROLE_SUPER_ADMIN],
		plans: [], // all plans
	},
	{
		label: 'Templates',
		route: ROUTE_PATH.TEMPLATES,
		roles: [USER_ROLE.ROLE_ADMIN, USER_ROLE.ROLE_SUPER_ADMIN],
		plans: [], // all plans
	},
];

export const AUTO_LOGOUT_SEC = 1800;
export const AUTO_LOGOUT_KEY = 'autoLogoutTime';

export const CLOSE_POPUP_HOT_KEY = 'Escape';

export const TIMEZONE = 'UTC';

export enum FILTER_KEY {
	SEARCH = 'search-filter'
}

export const EMPTY_VALUE_PLACEHOLDER = '-';
