import './Header.scss';

import { FC } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '_redux/store/Main.store';
import { IUserResponse, USER_PLAN, USER_ROLE } from 'interfaces/User';

import ClickableLogo from 'components/molecules/ClickableLogo/ClickableLogo';
import Navigation from 'components/molecules/Navigation/Navigation';
import ProfileDropdown from 'components/molecules/ProfileDropdown/ProfileDropdown';

type HeaderProps = {};

const Header: FC<HeaderProps> = () => {
	const user = useSelector((state: RootState) => state.Account);

	if (!user?.role) {
		return null;
	}

	return (
		<header className="app-header">
			<ClickableLogo />
			<Navigation role={user.role as USER_ROLE} plan={user.plan as USER_PLAN} />
			<ProfileDropdown user={user as IUserResponse} />
		</header>
	);
};

export default Header;
