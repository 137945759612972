import _ from 'lodash';
import { Reducer } from 'redux';

import { EditorActions, EditorActionTypes, IEditorReduxState } from '_redux/types/Editor.types';

const initialState: IEditorReduxState = {
	customJSComponentIds: [],
};

const EditorReducer: Reducer<IEditorReduxState, EditorActions> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case EditorActionTypes.ADD_JS_COMPONENT:
			const newIds = _.uniq([...state.customJSComponentIds, action.payload.newId]);

			return {
				...state,
				customJSComponentIds: newIds,
			};

		case EditorActionTypes.REMOVE_JS_COMPONENT:
			return {
				...state,
				customJSComponentIds: state.customJSComponentIds.filter((id) => id !== action.payload.idToRemove),
			};

		case EditorActionTypes.CLEAR_JS_COMPONENT:
			return {
				...state,
				customJSComponentIds: [],
			};

		default:
			return state;
	}
};

export default EditorReducer;
