import './Dropdown.scss';

import { FC, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '_redux/store/Main.store';
import { CLOSE_POPUP_HOT_KEY } from 'constants/Main';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useOnPressKey from 'hooks/useOnPressKey';
import { DropDownOption } from 'interfaces/Main';
import { IUserResponse } from 'interfaces/User';

import DropDownItem from 'components/atoms/dropdown/DropDownItem';

export type DropDownProps = {
	options: DropDownOption[];
};

const DropDown: FC<DropDownProps> = ({
	options,
	children,
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const user = useSelector((state: RootState) => state.Account) as IUserResponse;

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const [isOpen, setIsOpen] = useState(false);

	useOnPressKey(ref, handleClose, CLOSE_POPUP_HOT_KEY, isOpen);
	useOnClickOutside(ref, handleClose);

	const handleMenuTrigger = useCallback((e) => {
		setIsOpen((prevValue) => !prevValue);
		e.stopPropagation();
	}, []);

	const mappedOptions = options
		.filter((el) => !el.roles || el.roles.includes(user.role))
		.map((el) => ({
			...el,
			onClick: async (e) => {
				e.stopPropagation();
				await el.onClick(e);
				handleClose();
			},
		}));

	return (
		<div className='dropdown-wrapper' ref={ref}>
			<button className='dropdown-trigger' onClick={handleMenuTrigger}>
				{children}
			</button>

			{isOpen && (
				<ul className='dropdown-list'>
					{mappedOptions.map((item) => (
						<DropDownItem key={item.label} item={item} />
					))}
				</ul>
			)}
		</div>
	);
};

export default DropDown;
