import { FC } from 'react';

type ArrowTopIconProps = {
	className?: string;
}

const ArrowTopIcon: FC<ArrowTopIconProps> = ({
	className,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path d="M7 10L12 15L17 10H7Z" fill="#828282"/>
	</svg>
);

export default ArrowTopIcon;
