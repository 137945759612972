export interface IUserResponse {
	id: number;
	email: string;
	role: USER_ROLE;
	plan: USER_PLAN;
	firstname: string | null;
	lastname: string | null;
	phone: string | null;
	skype: string | null;
	telegram: string | null;
	isBlocked: boolean;
	cardNumber: string | null;
	cardMonth: string | null;
	cardYear: string | null;
	cardUid: string | null;
	subscriptionUid: string | null;
	chargeError: string | null;
}

export interface IUserInListResponse {
	id: number;
	email: string;
	roles: [USER_ROLE],
	plan: USER_PLAN;
	firstname: string | null;
	lastname: string | null;
	createdAt: string;
	countSites: number;
	isBlocked: boolean;
	subscriptionEnd: string | null;
	subscriptionUid: string | null;
	subscriptionPrice: string | null;
	chargeError: string | null;
}

export enum USER_ROLE {
	ROLE_USER = 'ROLE_USER',
	ROLE_ADMIN = 'ROLE_ADMIN',
	ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
}

export enum USER_PLAN {
	FREE = 'free',
	TRIAL = 'trial',
	PREMIUM = 'premium',
	UNPAID = 'unpaid',
}

export interface ICreateToken {
	email: string;
	password: string;
}

export interface IResetPasswordRequest {
	email: string;
}

export interface IConfirmResetPasswordRequest {
	password: string;
}

export interface IConfirmResetPasswordForm extends IConfirmResetPasswordRequest {
	repeatPassword: string;
}

export interface ITokenResponse {
	token: string;
	refreshToken: string;
	userId: number;
	expiresIn: number
}

export interface ICreateUserRequest {
	email: string;
	password: string;
	isTermsAccepted: boolean;
}

export interface ICreateUserForm extends ICreateUserRequest {
	repeatPassword: string;
	cardNumber: string;
	cardMonth: string;
	cardYear: string;
}

export interface IUpdateUserRequest {
	firstname?: string | null;
	lastname?: string | null;
	phone?: string | null;
	skype?: string | null;
	telegram?: string | null;
	cardNumber?: string | null;
	cardMonth?: string | null;
	cardYear?: string | null;
}

export interface IUpdatePasswordRequest {
	oldPassword: string;
	password: string;
}

export interface IUpdatePasswordForm extends IUpdatePasswordRequest {
	repeatPassword: string;
}
