export enum VALIDATION_ERROR_CODE {
	ERROR_CODE = 'b083852c-c633-4c11-a149-74fa287eee42',
	ACCOUNT_STATUS_ERROR_CODE = 'n583452f-k333-4c31-a179-74fh287reg73',
}

interface IViolation<TCode = string> {
	propertyPath: string;
	message: string;
	code: TCode;
}

interface IValidationViolation extends Omit<IViolation, 'code'> {
	code: string | null;
}

export interface IAuthError {
	type: string;
	title: string;
	detail?: string;
	code: number;
	message: string;
	violations: Array<IViolation<VALIDATION_ERROR_CODE>>;
}

export interface IUniqueValidationError {
	type: string;
	title: string;
	detail: string;
	violations: IViolation[];
	existingUniqueEntities: Array<{
		uniquePropertyPaths: string[];
		entity: Record<string, unknown>;
	}>
}

export interface IValidationError {
	type: string;
	title: string;
	detail: string;
	violations: IValidationViolation[];
}

export type ErrorUnion = IAuthError | IUniqueValidationError | IValidationError;
