import { addSeconds, isBefore, parseISO } from 'date-fns';
import _ from 'lodash';
import { getStoredState } from 'redux-persist';

import { clearAccountInfo } from '_redux/actions/Account.actions';
import { clearTokenInfo, saveToken, setTokenFromStorage } from '_redux/actions/Token.actions';
import { persistConfig, store } from '_redux/store/Main.store';
import { ITokenState } from '_redux/types/Token.types';
import { API_PATH,
	METHOD,
	TOKEN_UPDATE_BUFFER } from 'constants/Request';
import axiosHttp from 'utils/appFetch/axios';

class TokenManager {
	private static instance: TokenManager;

	private isPending: boolean;

	private tokenPromise?: Promise<void>;

	private constructor() {
		this.isPending = false;
	}

	public static getInstance(): TokenManager {
		if (!TokenManager.instance) {
			TokenManager.instance = new TokenManager();
		}

		return TokenManager.instance;
	}

	public updateTokenIfNeeded = async (): Promise<void> => {
		if (!this.isNeedToUpdate()) {
			return;
		}

		if (this.isPending) {
			await this.tokenPromise;
			this.isPending = false;
		} else {
			this.isPending = true;
			this.tokenPromise = this.updateToken();
			await this.tokenPromise;
		}
	};

	public isNeedToUpdate = (): boolean => {
		const {
			date, expiresIn,
		} = store.getState().Token;

		if (!date || !expiresIn) {
			return false;
		}

		const dateIsBefore = isBefore(
			addSeconds(parseISO(date), expiresIn - TOKEN_UPDATE_BUFFER),
			new Date(),
		);

		return dateIsBefore;
	};

	public checkToken = async (url: string): Promise<void> => {
		const {
			token,
		} = store.getState().Token;

		if (this.isNeedToUpdate() && url !== API_PATH.REFRESH_TOKEN) {
			await this.replaceTokenIfNeeded();
		}

		if (
			!_.isNull(token) &&
      this.isNeedToUpdate() &&
      url !== API_PATH.REFRESH_TOKEN
		) {
			await this.updateTokenIfNeeded();
		}
	};

	private replaceTokenIfNeeded = async (): Promise<void> => {
		const {
			Token,
		} = (await getStoredState(
			persistConfig,
		)) as {
			Token: ITokenState;
		};
		const {
			date,
		} = store.getState().Token;

		if (date && parseISO(Token.date as string) > parseISO(date)) {
			store.dispatch(
				setTokenFromStorage(Token),
			);
		}
	};

	private updateToken = async (): Promise<void> => {
		const {
			refreshToken,
		} = store.getState().Token;

		try {
			const response = await axiosHttp({
				url: API_PATH.REFRESH_TOKEN,
				method: METHOD.POST,
				data: {
					refreshToken,
				},
			});

			const token = response.data;

			store.dispatch(
				saveToken({
					expiresIn: token.expiresIn,
					refreshToken: token.refreshToken,
					token: token.token,
					userId: token.userId,
				}),
			);
		} catch (error) {
			store.dispatch(clearAccountInfo());
			store.dispatch(clearTokenInfo());
		} finally {
			this.isPending = false;
		}
	};
}

export const tokenUtils = TokenManager.getInstance();
