import './Modal.scss';

import clsx from 'clsx';
import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';

import { CLOSE_POPUP_HOT_KEY } from 'constants/Main';
import useOnPressKey from 'hooks/useOnPressKey';

import Button from 'components/atoms/buttons/Button';
import ClearIcon from 'components/atoms/svg/ClearIcon';

type ModalProps = {
	className?: string;
	isOpen: boolean;
	title?: string;
	elementId?: string;
	onOverlayClick?: () => void;
	handleClose: () => void;
}

const Modal: FC<ModalProps> = ({
	children,
	className,
	isOpen = false,
	onOverlayClick,
	elementId = 'root',
	title = '',
	handleClose,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	useOnPressKey(ref, handleClose, CLOSE_POPUP_HOT_KEY, isOpen);

	if (isOpen === false) {
		return null;
	}

	return createPortal(
		<div role="dialog" aria-modal className={clsx('modal-wrapper', className)}>
			<div className='modal-overlay' onClick={onOverlayClick} />
			<div ref={ref} className='modal-container'>
				<Button
					className='close-modal-icon'
					label=''
					icon={<ClearIcon />}
					onClick={handleClose}
				/>
				<h3 className='modal-heading'>{title}</h3>
				{children}
			</div>
		</div>,
		document.getElementById(elementId) as HTMLElement,
	);
};

export default Modal;
