import { AnyAction, Reducer } from 'redux';

import { GlobalErrorActionTypes, IGlobalErrorState } from '_redux/types/GlobalError.types';

const initialState: IGlobalErrorState = {
	isError: false,
};

const GlobalErrorReducer: Reducer<IGlobalErrorState, AnyAction> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case GlobalErrorActionTypes.SET_ERROR:
			return {
				...state,
				isError: true,
			};

		case GlobalErrorActionTypes.CLEAR_ERROR:
			return initialState;

		default:
			return state;
	}
};

export default GlobalErrorReducer;
