import './Modal.scss';

import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { clearAccountInfo } from '_redux/actions/Account.actions';
import { clearErrorInfo } from '_redux/actions/GlobalError.actions';
import { clearTokenInfo } from '_redux/actions/Token.actions';
import { RootState } from '_redux/store/Main.store';
import { ROUTE_PATH } from 'constants/Main';

import Button from 'components/atoms/buttons/Button';
import Modal from 'components/atoms/modals/Modal';

export type AccountAccessHandlerProps = {}

const AccountAccessHandler: FC<AccountAccessHandlerProps> = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const {
		isError,
	} = useSelector((state: RootState) => state.GlobalError);

	const handleClose = useCallback(() => {
		if (isError) {
			dispatch(clearErrorInfo());
			dispatch(clearTokenInfo());
			dispatch(clearAccountInfo());
			history.push(ROUTE_PATH.LANDING_PAGE);
		}
	}, [dispatch, history, isError]);

	if (location.pathname === ROUTE_PATH.LOGIN) {
		dispatch(clearErrorInfo());

		return null;
	}

	return (
		<Modal
			isOpen={isError}
			handleClose={handleClose}
			title='An error occured'
		>
			<div className='info-modal'>
				<div>
					<span>There is an error accessing your data, please contact support.</span>
				</div>
				<Button
					label='Ok'
					onClick={handleClose}
				/>

			</div>
		</Modal>
	);
};

export default AccountAccessHandler;
