export interface ITokenState {
	readonly date: string | null;
	readonly expiresIn: number | null;
	readonly refreshToken: string | null;
	readonly token: string | null;
	readonly userId: number | null;
}

export enum TokenActionTypes {
	LOGOUT = '@@token/LOGOUT',
	SAVE_TOKEN = '@@token/SAVE_TOKEN',
	SET_FROM_STORAGE = '@@token/SET_FROM_STORAGE'
}
